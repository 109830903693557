import { ReactElement, useCallback, useMemo } from 'react'
import { loadCaseLabels, loadCategoryToShort } from '@editorUtils'
import { useResultsQueryParams } from '@resultsHooks'
import { localToGlobalForce } from '@scene'
import { reject } from 'lodash-es'
import numeral from 'numeral'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, Stack, Typography } from '@mui/material'
import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-premium'
import { Box } from '@ui/structure'
import { useControlStore, useResultsStore } from '@editorStores'
import { useElementLabel } from '@editorHooks'
import { loadCaseTypeComparator } from 'src/utils/loads'
import LoadSourceLabel from '../LoadSourceLabel'

interface Props {
  lineLoads: LineLoadWithDomain[]
  loadTracingMap: LoadSource[]
}

const lineLoadForceFormatter = (value: number) => {
  const valueInKilos = value / 1000
  const formatter = '0[.][00]'
  return `${numeral(valueInKilos).format(formatter)}`
}

const lineLoadForceValueGetter = (force: Force, domain: Domain) => {
  const globalForce = localToGlobalForce(force, domain)
  const globalForceZ = globalForce.z
  return Math.abs(globalForceZ) < 1.0e-3 ? 0 : globalForceZ
}

const LineLoadGridToolbar = () => {
  return (
    <GridToolbarContainer>
      <Stack direction="row" spacing={1} sx={{ width: '100%' }}>
        <GridToolbarFilterButton slotProps={{ button: { size: 'small' } }} />
        <GridToolbarQuickFilter sx={{ width: '100%' }} />
      </Stack>
    </GridToolbarContainer>
  )
}

const LineLoadTable = ({ lineLoads, loadTracingMap }: Props): ReactElement => {
  // Access results store directly
  const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)
  const lineLoadGuids = useMemo(() => lineLoads.map(load => load.guid), [lineLoads])
  const hiddenLoads = useResultsStore(state => state.hiddenLoads)
  const setHiddenLoads = useResultsStore(state => state.setHiddenLoads)
  const getLabel = useElementLabel()
  const {
    params: { selectedLoad },
    actions: { selectLoad },
  } = useResultsQueryParams()

  const allLoadsAreHidden = useMemo(() => {
    return lineLoadGuids.every(guid => hiddenLoads.includes(guid))
  }, [lineLoadGuids, hiddenLoads])

  const setAllLoadsVisible = useCallback(() => {
    // only set the given lineLoads to visible
    setHiddenLoads(reject(hiddenLoads, guid => lineLoadGuids.includes(guid)))
  }, [lineLoadGuids, hiddenLoads, setHiddenLoads])

  const setAllLoadsHidden = useCallback(() => {
    // Combine existing hidden loads with the line load guids, removing duplicates
    setHiddenLoads([...new Set([...hiddenLoads, ...lineLoadGuids])])
  }, [lineLoadGuids, hiddenLoads, setHiddenLoads])

  const handleVisibilityColumnHeaderClick = useCallback(() => {
    if (allLoadsAreHidden) {
      setAllLoadsVisible()
    } else {
      setAllLoadsHidden()
    }
  }, [allLoadsAreHidden, setAllLoadsHidden, setAllLoadsVisible])

  const toggleHideLoad = useCallback(
    (loadGuid: string) => {
      if (hiddenLoads.includes(loadGuid)) {
        setHiddenLoads(reject(hiddenLoads, hiddenLoad => hiddenLoad === loadGuid))
      } else {
        setHiddenLoads([...hiddenLoads, loadGuid])
      }
    },
    [hiddenLoads, setHiddenLoads],
  )

  const rows = useMemo(() => {
    return lineLoads.map(load => {
      const source = loadTracingMap.find(
        loadSource => loadSource.load_guid === load.guid,
      )?.source_guid
      return {
        id: load.guid,
        ...load,
        startPosition: (load.domain?.length as number) * load.relative_start,
        endPosition: (load.domain?.length as number) * load.relative_end,
        sourceGuid: source,
        sourceLabel: source ? getLabel(source) : '',
        visible: !hiddenLoads.includes(load.guid),
        // fields for filtering
        loadCaseType: load.load_case.load_case_type,
        loadCaseCategory: load.load_case.category,
        loadCaseTypeLabelShort:
          loadCaseLabels[load.load_case.category][load.load_case.load_case_type as LoadCaseTypes]
            ?.short,
        loadCaseTypeLabel:
          loadCaseLabels[load.load_case.category][load.load_case.load_case_type as LoadCaseTypes]
            ?.label,
        loadCaseCategoryShort: loadCategoryToShort[load.load_case.category],
      }
    })
  }, [lineLoads, loadTracingMap, hiddenLoads, getLabel])

  const defaultColumnProps = {
    renderHeaderFilter: () => null,
  }

  const columnWidths = isDrawerExpanded
    ? {
        visibility: 30,
        q_start: 75,
        q_end: 75,
        loadCategoryType: 80,
        loadCaseType: 50,
        source: 80,
        startPosition: 65,
        endPosition: 65,
      }
    : {
        visibility: 30,
        q_start: 75,
        q_end: 75,
        loadCategoryType: 35,
        loadCaseType: 50,
        source: 60,
        startPosition: 65,
        endPosition: 65,
      }

  const columns: GridColDef[] = [
    {
      ...defaultColumnProps,
      field: 'visible',
      headerName: 'Sichtbarkeit',
      width: columnWidths.visibility,
      minWidth: columnWidths.visibility,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      type: 'boolean',
      renderHeader: () => {
        return (
          <IconButton onClick={handleVisibilityColumnHeaderClick} size="small">
            {allLoadsAreHidden ? (
              <VisibilityOff fontSize="inherit" />
            ) : (
              <Visibility fontSize="inherit" />
            )}
          </IconButton>
        )
      },
      renderCell: (params: GridRenderCellParams) => (
        <IconButton onClick={() => toggleHideLoad(params.row.guid)} size="small">
          {params.row.visible ? (
            <Visibility fontSize="inherit" />
          ) : (
            <VisibilityOff fontSize="inherit" />
          )}
        </IconButton>
      ),
    },
    {
      ...defaultColumnProps,
      field: 'loadCaseType',
      headerName: 'Fall',
      width: columnWidths.loadCaseType,
      minWidth: columnWidths.loadCaseType,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      valueGetter: (_, row: LineLoadWithDomain) => row.load_case,
      valueFormatter: (value: LoadCase) =>
        loadCaseLabels[value.category][value.load_case_type as LoadCaseTypes]?.short,
      sortComparator: (v1, v2) => {
        return loadCaseTypeComparator(v1.load_case_type, v2.load_case_type)
      },
    },
    {
      ...defaultColumnProps,
      field: 'loadCaseTypeLabelShort',
      headerName: 'FallKurzbez.',
      width: columnWidths.loadCaseType,
      minWidth: columnWidths.loadCaseType,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      hideable: true,
    },
    {
      ...defaultColumnProps,
      field: 'q_start',
      headerName: 'q_a',
      width: columnWidths.q_start,
      minWidth: columnWidths.q_start,
      sortable: true,
      headerAlign: 'center',
      align: 'right',
      renderHeader: () => {
        return (
          <Stack direction="column" spacing={0} padding={0} alignItems="center">
            <Typography>
              q<sub>S</sub>
            </Typography>
            <Typography>[kN/m]</Typography>
          </Stack>
        )
      },
      valueGetter: (_, row: LineLoadWithDomain) =>
        lineLoadForceValueGetter(row.start, row.domain as Domain),
      valueFormatter: lineLoadForceFormatter,
      type: 'number',
    },
    {
      ...defaultColumnProps,
      field: 'q_end',
      headerName: 'q_e',
      width: columnWidths.q_end,
      sortable: true,
      headerAlign: 'center',
      align: 'right',
      renderHeader: () => {
        return (
          <Stack direction="column" spacing={0} padding={0} alignItems="center">
            <Typography>
              q<sub>E</sub>
            </Typography>
            <Typography>[kN/m]</Typography>
          </Stack>
        )
      },
      valueGetter: (_, row: LineLoadWithDomain) =>
        lineLoadForceValueGetter(row.end, row.domain as Domain),
      valueFormatter: lineLoadForceFormatter,
      type: 'number',
    },
    {
      ...defaultColumnProps,
      field: 'startPosition',
      headerName: 'Start',
      width: columnWidths.startPosition,
      headerAlign: 'center',
      align: 'right',
      sortable: true,
      valueFormatter: (value: number) => `${numeral(value).format('0.00')} m`,
    },
    {
      ...defaultColumnProps,
      field: 'endPosition',
      headerName: 'Ende',
      width: columnWidths.endPosition,
      headerAlign: 'center',
      align: 'right',
      sortable: true,
      valueFormatter: (value: number) => `${numeral(value).format('0.00')} m`,
    },
    {
      field: 'sourceLabel',
      headerName: 'von',
      width: columnWidths.source,
      minWidth: columnWidths.source,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      renderCell: (params: GridRenderCellParams) => (
        <LoadSourceLabel source={params.row.sourceGuid} />
      ),
    },
    ...(isDrawerExpanded
      ? ([
          {
            field: 'loadCaseCategoryShort',
            headerName: 'Kat.',
            width: columnWidths.loadCategoryType,
            minWidth: columnWidths.loadCategoryType,
            headerAlign: 'center',
            align: 'center',
            sortable: true,
          },
        ] as GridColDef[])
      : []),
  ]

  const isDrawerExpandedExtraProps = isDrawerExpanded
    ? {
        headerFilters: true,
        headerFilterHeight: 75,
      }
    : {}

  const slots = isDrawerExpanded
    ? {
        toolbar: LineLoadGridToolbar,
        headerFilterMenu: null,
      }
    : {
        toolbar: LineLoadGridToolbar,
      }

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <DataGridPremium
        rows={rows}
        columns={columns}
        columnHeaderHeight={75}
        density="compact"
        hideFooter
        onRowClick={params => {
          selectLoad(params.row.guid)
        }}
        rowSelectionModel={selectedLoad ? [selectedLoad] : []}
        initialState={{
          sorting: {
            sortModel: [{ field: 'loadCaseType', sort: 'asc' }],
          },
          pagination: {
            paginationModel: { pageSize: 25 },
          },
          columns: {
            columnVisibilityModel: {
              loadCaseTypeLabelShort: false,
            },
          },
        }}
        autoPageSize
        getRowId={row => row.id}
        disableRowSelectionOnClick
        disableRowGrouping
        slots={slots}
        sx={{
          '& .MuiDataGrid-cell': {
            padding: 0,
          },
          '& .MuiDataGrid-columnHeader': {
            padding: 0,
          },
        }}
        {...isDrawerExpandedExtraProps}
      />
    </Box>
  )
}

export default LineLoadTable
