import { lightBlue } from '@mui/material/colors'
import { darken, lighten, styled, Theme } from '@mui/material/styles'
import { DataGridPremium } from '@mui/x-data-grid-premium'

export const styledDataGridErrorClass = 'app-theme--RowWarning'
export const styledDataGridWarningClass = 'app-theme--CellError'
export const styledDataGridSuccessClass = 'app-theme--CellSuccess'
export const styledDataGridHighlightClass = 'app-theme--Highlight'

const getBackgroundColor = (color: string, theme: Theme, coefficient: number) => ({
  backgroundColor: darken(color, coefficient),
  ...theme.applyStyles('light', {
    backgroundColor: lighten(color, coefficient),
  }),
})

const StyledDataGrid = styled(DataGridPremium)(({ theme }) => ({
  '& .MuiDataGrid-cell.Mui-selected': {
    backgroundColor: theme.palette.action.selected,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '& .MuiDataGrid-cell': {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
  },
  [`& .${styledDataGridErrorClass}`]: {
    ...getBackgroundColor(theme.palette.error.main, theme, 0.7),
    '&:hover': {
      ...getBackgroundColor(theme.palette.error.main, theme, 0.6),
    },
    '&.Mui-selected': {
      ...getBackgroundColor(theme.palette.error.main, theme, 0.5),
      '&:hover': {
        ...getBackgroundColor(theme.palette.error.main, theme, 0.4),
      },
    },
  },
  [`& .${styledDataGridWarningClass}`]: {
    ...getBackgroundColor(theme.palette.warning.main, theme, 0.7),
    '&:hover': {
      ...getBackgroundColor(theme.palette.warning.main, theme, 0.6),
    },
    '&.Mui-selected': {
      ...getBackgroundColor(theme.palette.warning.main, theme, 0.5),
      '&:hover': {
        ...getBackgroundColor(theme.palette.warning.main, theme, 0.4),
      },
    },
  },
  [`& .${styledDataGridSuccessClass}`]: {
    ...getBackgroundColor(theme.palette.success.main, theme, 0.7),
    '&:hover': {
      ...getBackgroundColor(theme.palette.success.main, theme, 0.6),
    },
    '&.Mui-selected': {
      ...getBackgroundColor(theme.palette.success.main, theme, 0.5),
      '&:hover': {
        ...getBackgroundColor(theme.palette.success.main, theme, 0.4),
      },
    },
  },
  [`& .${styledDataGridHighlightClass}`]: {
    ...getBackgroundColor(lightBlue[200], theme, 0.7),
    '&:hover': {
      ...getBackgroundColor(lightBlue[200], theme, 0.6),
    },
    '&.Mui-selected': {
      ...getBackgroundColor(lightBlue[200], theme, 0.5),
      '&:hover': {
        ...getBackgroundColor(lightBlue[200], theme, 0.4),
      },
    },
  },
}))

export default StyledDataGrid
