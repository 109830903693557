import { ReactElement, useEffect, useMemo } from 'react'
import { Outlet } from 'react-router-dom'
import { isArray, split } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useAuth0 } from '@auth0/auth0-react'
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query'
import { LoadingIndicator, ErrorState } from '@ui/feedback'
import { NavbarPortalCenter, NavBreadcrumbs } from '@ui/navigation'
import {
  ElementSelectionStoreProvider,
  LabelsStoreProvider,
  ResultsStoreProvider,
  ElementLoadStoreProvider,
  EditElementStoreProvider,
  StructuralPlanningStoreProvider,
  ControlStoreProvider,
  ModelStoreProvider,
  SystemManagerStoreProvider,
} from '@editorStores'
import {
  getModel,
  getProjectAssemblies,
  getSelectedAssemblies,
  getProject,
  getAssemblyAssignment,
  getProjectElementCrossSections,
  getElementCrossSectionsSelection,
  getElementCrossSectionAssignment,
} from '@queries'
import {
  useReducedNavigation,
  usePersistentParams,
  usePosthogSession,
  useAddPosthogEventProperties,
} from 'src/hooks'
import { useQueriesLoading } from 'src/state/hooks'
import {
  getProjectConnectionPrioritization,
  getProjectConnections,
} from 'src/state/queries/connections'
import { getElementLabels, getPositionLabels } from 'src/state/queries/labels'
import ProcessNavigation from './components/ProcessNavigation'
import { useEditorNavigation } from './hooks'
import { SceneStoreProvider } from './stores/sceneStore'

const Editor = (): ReactElement => {
  useReducedNavigation()
  const { currentRoute: { label: currentRouteName } = {} } = useEditorNavigation()
  const { enqueueSnackbar } = useSnackbar()
  const onError = () => enqueueSnackbar('Fehler beim Laden der Projektdaten', { variant: 'error' })
  const { projectId }: { projectId?: string } = usePersistentParams()

  const { user } = useAuth0()

  usePosthogSession(projectId)

  // be carefull when editing the queries (do not change the order) and also
  // edit the queryResults below
  const queryResults = useQueries({
    queries: [
      {
        queryKey: getModel.getKey(projectId),
        queryFn: () => getModel.request(projectId),
        onError,
      },
      {
        queryKey: getSelectedAssemblies.getKey(projectId),
        queryFn: () => getSelectedAssemblies.request(projectId),
        onError,
      },
      {
        queryKey: getProjectAssemblies.getKey(projectId),
        queryFn: () => getProjectAssemblies.request(projectId),
        onError,
      },
      {
        queryKey: getAssemblyAssignment.getKey(projectId),
        queryFn: () => getAssemblyAssignment.request(projectId),
        onError,
      },
      {
        queryKey: getProjectConnections.getKey(projectId),
        queryFn: () => getProjectConnections.request(projectId),
        onError,
      },
      {
        queryKey: getProjectConnectionPrioritization.getKey(projectId),
        queryFn: () => getProjectConnectionPrioritization.request(projectId),
        onError,
      },
      {
        queryKey: getProjectElementCrossSections.getKey(projectId),
        queryFn: () => getProjectElementCrossSections.request(projectId),
        onError,
      },
      {
        queryKey: getElementCrossSectionsSelection.getKey(projectId),
        queryFn: () => getElementCrossSectionsSelection.request(projectId),
        onError,
      },
      {
        queryKey: getElementCrossSectionAssignment.getKey(projectId),
        queryFn: () => getElementCrossSectionAssignment.request(projectId),
        onError,
      },
      {
        queryKey: getProject.getKey(projectId),
        queryFn: () => getProject.request(projectId),
        onError,
      },
    ],
  })

  const elementLabels = useQuery({
    queryKey: getElementLabels.getKey(projectId),
    queryFn: () => getElementLabels.request(projectId),
    onError,
  })

  const positionLabels = useQuery({
    queryKey: getPositionLabels.getKey(projectId),
    queryFn: () => getPositionLabels.request(projectId),
    onError,
  })

  const queryClient = useQueryClient()
  useEffect(
    () => () => {
      queryClient.removeQueries({
        predicate: el => isArray(el.queryKey) && el.queryKey.includes(projectId),
      })
    },
    [projectId, queryClient],
  )

  const isLoading = useQueriesLoading(queryResults)

  const [
    model,
    selectedAssemblies,
    assemblies,
    assemblyAssignment,
    connections,
    connectionPrioritization,
    elementCrossSections,
    elementCrossSectionsSelection,
    elementCrossSectionAssignment,
    project,
  ] = queryResults

  const posthogEventProperties = useMemo(
    () => ({
      projectId,
      name: project.data?.name,
      responsibleEngineer: project.data?.responsible_engineer_name,
      constructionCompany: project.data?.construction_company_name,
      processor: project.data?.processor_name,
    }),
    [
      project.data?.construction_company_name,
      project.data?.name,
      project.data?.processor_name,
      project.data?.responsible_engineer_name,
      projectId,
    ],
  )

  useAddPosthogEventProperties(posthogEventProperties)

  if (isLoading) {
    return <LoadingIndicator />
  }

  if (!model.data) {
    return (
      <>
        <ErrorState error="Bitte importieren Sie zunächst Ihr Modell" />
        <NavbarPortalCenter>
          <NavBreadcrumbs main={`${user?.email} - ${project.data?.name}`} />
        </NavbarPortalCenter>
      </>
    )
  }

  return (
    <ModelStoreProvider refreshAt={model.dataUpdatedAt} model={model.data} project={project.data}>
      <SystemManagerStoreProvider
        selectedAssemblies={selectedAssemblies.data}
        assemblies={assemblies.data}
        assemblyAssignment={assemblyAssignment.data}
        connections={connections.data}
        prioritization={connectionPrioritization.data}
        elementCrossSections={elementCrossSections.data}
        elementCrossSectionsSelection={elementCrossSectionsSelection.data}
        elementCrossSectionAssignment={elementCrossSectionAssignment.data}
      >
        <LabelsStoreProvider
          elementLabels={elementLabels.data}
          positionLabels={positionLabels.data}
        >
          <SceneStoreProvider>
            <ControlStoreProvider>
              <ElementSelectionStoreProvider>
                <StructuralPlanningStoreProvider>
                  <ResultsStoreProvider>
                    <ElementLoadStoreProvider>
                      <EditElementStoreProvider>
                        <Outlet />
                        <ProcessNavigation />
                        <NavbarPortalCenter>
                          <NavBreadcrumbs
                            main={`${project.data?.name} | ${split(user?.email, '@')[0]}`}
                            crumbs={[currentRouteName as string]}
                          />
                        </NavbarPortalCenter>
                      </EditElementStoreProvider>
                    </ElementLoadStoreProvider>
                  </ResultsStoreProvider>
                </StructuralPlanningStoreProvider>
              </ElementSelectionStoreProvider>
            </ControlStoreProvider>
          </SceneStoreProvider>
        </LabelsStoreProvider>
      </SystemManagerStoreProvider>
    </ModelStoreProvider>
  )
}

export default Editor
