import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { NestedMenuItem } from '@ui/navigation/NestedMenu'
import { getBackendPerformanceSettings } from '@queries'
import { resetPositionChecks, setBackendPerformanceSettings } from '@mutations'
import { COMPUTE_CHECKS_WORKER_STRATEGIES } from 'src/components/ui/navigation/Navbar'
import { buildErrorMessage } from 'src/constants'

type ProjectSettingsProps = {
  parentMenuOpen: boolean
}

function ProjectSettings({ parentMenuOpen }: ProjectSettingsProps) {
  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const queryClient = useQueryClient()

  const backendPerformanceSettingsQuery = useQuery({
    queryKey: getBackendPerformanceSettings.getKey(projectId),
    queryFn: () => getBackendPerformanceSettings.request(projectId),
    enabled: !!projectId,
    onError: () => {
      enqueueSnackbar('Fehler beim Laden der Einstellungen für die Backend-Performance.')
    },
  }) as { isFetching: boolean; data: BackendPerformanceSettings }

  const { mutateAsync: saveBackendPerformanceSettings } = useMutation(
    (data: BackendPerformanceSettings) => setBackendPerformanceSettings.request(projectId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getBackendPerformanceSettings.getKey(projectId))
        enqueueSnackbar('Performance-Einstellungen erfolgreich gespeichert', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Performance-Einstellungen'),
          { variant: 'error' },
        )
      },
    },
  )

  const { mutateAsync: resetPositionChecksMutation } = useMutation(
    () => resetPositionChecks.request(projectId),
    {
      onSuccess: () => {
        enqueueSnackbar('Ergebnisse wurden erfolgreich zurückgesetzt', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Zurücksetzen der Ergebnisse'), {
          variant: 'error',
        })
      },
    },
  )

  return (
    <div>
      {projectId && (
        <NestedMenuItem
          label="Projekt-Einstellungen"
          parentMenuOpen={parentMenuOpen}
          rightIcon={<ChevronRightIcon />}
          data-cy="project-settings-menu"
        >
          <NestedMenuItem
            label="Performance (Backend)"
            parentMenuOpen={parentMenuOpen}
            rightIcon={<ChevronRightIcon />}
            data-cy="backend-performance-settings-menu"
          >
            <MenuItem>
              <Tooltip title="Auswahl des worker Tasks" placement="left">
                <Stack spacing={1}>
                  <Typography>
                    Optimierungsstrategie für &quot;Ergebnisse berechnen&quot;
                  </Typography>
                  <Select
                    value={backendPerformanceSettingsQuery.data?.worker_strategy || ''}
                    onChange={(e: SelectChangeEvent) => {
                      const newSettings = {
                        ...backendPerformanceSettingsQuery.data,
                        worker_strategy: e.target.value as ComputeChecksWorkerStrategyType,
                      }
                      saveBackendPerformanceSettings(newSettings)
                    }}
                    size="small"
                    sx={{ minWidth: 200 }}
                    data-cy="backend-performance-settings-strategy-select"
                  >
                    {Object.values(COMPUTE_CHECKS_WORKER_STRATEGIES).map(strategy => (
                      <MenuItem key={strategy} value={strategy} data-cy={`strategy-${strategy}`}>
                        {strategy}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
              </Tooltip>
            </MenuItem>
            <MenuItem>
              <Tooltip title="Anzahl der parallel laufenden Threads" placement="left">
                <Stack spacing={1}>
                  <Typography>Anzahl Threads</Typography>
                  <TextField
                    type="number"
                    size="small"
                    value={
                      backendPerformanceSettingsQuery.data?.worker_options?.num_concurrent_tasks ||
                      1
                    }
                    onChange={e => {
                      const value = parseInt(e.target.value)
                      if (value > 0) {
                        const newSettings = {
                          ...backendPerformanceSettingsQuery.data,
                          worker_options: {
                            ...backendPerformanceSettingsQuery.data?.worker_options,
                            num_concurrent_tasks: value,
                          },
                        }
                        saveBackendPerformanceSettings(newSettings)
                      }
                    }}
                    inputProps={{
                      min: 1,
                    }}
                    sx={{ minWidth: 200 }}
                    data-cy="backend-performance-settings-num-concurrent-tasks-input"
                  />
                </Stack>
              </Tooltip>
            </MenuItem>
            <MenuItem>
              <Button
                onClick={() => resetPositionChecksMutation()}
                color="primary"
                variant="outlined"
                size="small"
                fullWidth
              >
                Ergebnisse zurücksetzen
              </Button>
            </MenuItem>
          </NestedMenuItem>
        </NestedMenuItem>
      )}
    </div>
  )
}

export default ProjectSettings
