import { Vector3 } from 'three'
import { toImmutable } from '@modugen/scene/lib/utils'
import {
  scaleLineLoadForce,
  lineLoadForceFormatter,
  pointLoadForceFormatter,
  scalePointLoadForce,
} from '../LoadMesh/utils'
import {
  loadPointOnDomain,
  magnitudeArrowDirection,
  scaleVector,
  localToGlobalForce,
} from '../utils'

interface LoadDisplayInputData {
  domainStart: Point
  domainEnd: Point
  scale: number
  domain: Domain
}
interface LineLoadDisplayInputData extends LoadDisplayInputData {
  load: LineLoad
}

interface PointLoadDisplayInputData extends LoadDisplayInputData {
  load: PointLoad
}

export const createLineLoadDisplayData = ({
  domain,
  load,
  domainStart,
  domainEnd,
  scale,
}: LineLoadDisplayInputData) => {
  const { relative_end, relative_start, start, end } = load

  const loadStartPoint = loadPointOnDomain(relative_start, domainStart, domainEnd)
  const loadEndPoint = loadPointOnDomain(relative_end, domainStart, domainEnd)
  // to global cooredinate
  const forceStart = localToGlobalForce(start, domain).multiplyScalar(-1)
  const forceEnd = localToGlobalForce(end, domain).multiplyScalar(-1)
  // scale load display size
  const scaledForceStart =
    forceStart.length() < 1.0e-3 ? new Vector3(0, 0, 0) : scaleVector(forceStart, scale)
  // this ensure the geometry points are in the same plane when the force is too small
  const scaledForceEnd =
    forceEnd.length() < 1.0e-3 ? new Vector3(0, 0, 0) : scaleVector(forceEnd, scale)
  // get top ends of the shape
  const loadStartPointPlusForce = loadStartPoint.clone().add(scaledForceStart)
  const loadEndPointPlusForce = loadEndPoint.clone().add(scaledForceEnd)
  // points that will form the shape
  const points = [loadStartPoint, loadEndPoint, loadEndPointPlusForce, loadStartPointPlusForce]
  // for the directional arrow display
  const loadStartMagnitudePoint = magnitudeArrowDirection(start, points[0], points[3])
  const loadEndMagnitudePoint = magnitudeArrowDirection(start, points[1], points[2])

  return {
    loadType: 'line-load',
    label: `${lineLoadForceFormatter(scaleLineLoadForce(scale, scaledForceStart.z))}`,
    endLabel: `${lineLoadForceFormatter(scaleLineLoadForce(scale, scaledForceEnd.z))}`,
    magnitudePoints: [loadStartMagnitudePoint, loadEndMagnitudePoint],
    shapeObject: {
      shape: {
        points: points.map(p => toImmutable(p)),
      },
      openings: [],
      domains: [],
      thickness: 0.05,
      storey: '',
    },
  }
}

export const createPointLoadDisplayData = ({
  load,
  domainStart,
  domainEnd,
  scale,
  domain,
}: PointLoadDisplayInputData) => {
  const { relative_position, force } = load

  const loadPoint = loadPointOnDomain(relative_position, domainStart, domainEnd)
  const globalForce = localToGlobalForce(force, domain).multiplyScalar(-1) // converForceFromLocalToGlobal(force, selectedDomain).multiplyScalar(-1)
  const scaledGlobalForce = scaleVector(globalForce, scale)
  const loadPointPlusForce = loadPoint.clone().add(scaledGlobalForce)
  const loadMagnitudePoint = magnitudeArrowDirection(force, loadPoint, loadPointPlusForce)

  return {
    loadType: 'point-load',
    label: `${pointLoadForceFormatter(scalePointLoadForce(scale, scaledGlobalForce.z))}`,
    magnitudePoints: [loadMagnitudePoint],
  }
}
