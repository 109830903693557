import { find, isArray, isUndefined, memoize } from 'lodash-es'
import {
  Box3,
  Vector3,
  Matrix4,
  Shape,
  Vector2,
  MeshStandardMaterial,
  MeshStandardMaterialParameters,
  LineBasicMaterialParameters,
  LineBasicMaterial,
  ExtrudeGeometry,
  EdgesGeometry,
} from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { absoluteTransmitterSnapTolerance } from './constants'

// extruded geometries return incorrect bounding box
// adapted from
// https://github.com/GridSpace/grid-apps/blob/96fa8fece3646ccab24ac3ca212322d1dbc278ba/src/mesh/api.js
// eslint-disable-next-line
export const box3expand = (box3: Box3, object: any) => {
  const geometry = object.geometry
  object.updateWorldMatrix(!!geometry, false)

  if (geometry?.attributes?.position) {
    // debugger
    const matrix = object.matrixWorld
    const position = geometry.attributes.position.clone()

    position.applyMatrix4(new Matrix4().extractRotation(matrix))

    const bounds = new Box3().setFromBufferAttribute(position)
    const bt = new Box3().copy(bounds)
    const m4 = new Matrix4()

    m4.setPosition(new Vector3().setFromMatrixPosition(object.matrixWorld))
    const { z } = new Box3().setFromObject(object).max
    bt.applyMatrix4(m4)
    bt.max.setZ(z)

    box3.union(bt)
  }

  const { children } = object

  for (let i = 0, l = children.length; i < l; i++) {
    box3expand(box3, children[i])
  }

  return box3
}

/**
 * Returns the normal based on the first three points of provided array
 */
export const getPolygonNormal = (points: ImmutableVector3[]): ImmutableVector3 => {
  if (points.length < 3) throw new Error('At least 3 Points have to be provided')

  const norm = new ImmutableVector3()
    .crossVectors(points[1].sub(points[0]), points[2].sub(points[0]))
    .normalize()

  return norm
}

export const getShapeLength = ([start, end]: Point[]) => {
  const bottomLeft = new Vector3(start.x, start.y, start.z)
  const bottomRight = new Vector3(end.x, end.y, end.z)

  return bottomLeft.distanceTo(bottomRight)
}

export const getPointAtPercentage = (
  start: ImmutableVector3,
  end: ImmutableVector3,
  percentage = 0,
): ImmutableVector3 => {
  const startVector = new ImmutableVector3(start.x, start.y, start.z)
  const endVector = new ImmutableVector3(end.x, end.y, end.z)
  const direction = endVector.sub(startVector)
  const length = direction.length()
  const normalizedDirection = direction.normalize().multiplyScalar(length * percentage)

  return startVector.add(normalizedDirection)
}

export const getDomainAndRelativePositionFromPoint = (
  domains: Domain[],
  point: Vector3,
): [Domain, number] | undefined => {
  return domains.reduce((acc, domain: Domain) => {
    const relative_position = getRelativePositionFromPoint(domain, point)

    if (relative_position >= 0 && relative_position <= 1) return [domain, relative_position]

    return acc
  }, undefined as [Domain, number] | undefined)
}

export const getRelativePositionFromPoint = (domain: Domain, point: Vector3): number => {
  const domainVector = getVectorFromDomain(domain)
  const domainStart = new Vector3(domain.start.x, domain.start.y, domain.start.z)
  const normalizedDomainVector = domainVector.clone().normalize()
  const startToPoint = point.clone().sub(domainStart)
  const pointOnTopDomain = startToPoint.clone().dot(normalizedDomainVector)
  const domainLength = domainVector.length()
  const relative_position = pointOnTopDomain / domainLength

  return relative_position
}

export const getVectorFromDomain = (domain: Domain) => {
  const { start, end } = domain
  const startVector = new Vector3(start.x, start.y, start.z)
  const endVector = new Vector3(end.x, end.y, end.z)

  return endVector.clone().sub(startVector)
}

export const toVector = (point: Vector2 | Force): Vector3 => {
  if (point instanceof Vector2) return new Vector3(point.x, point.y)
  if (isArray(point)) return new Vector3(...point)
  return new Vector3(point.x, point.y, point.z)
}

export const toVector2 = (point: Point | Vector3): Vector2 => {
  if (isArray(point)) return new Vector2(...point)
  return new Vector2(point.x, point.y)
}

export const getTopDomains = (domains: Domain[]) => {
  return domains.slice(2, domains.length - 1)
}

export const getDomainLength = (walls: ShapeObject[], elementGuid: string, domainGuid: string) => {
  const wall = find(walls, ['guid', elementGuid])
  if (!wall) return 0

  const domain = find(wall.domains, ({ guid }) => guid === domainGuid)
  if (!domain) return 0

  return domain.end.sub(domain.start).length()
}

export const getLength = (start: Point, end: Point): number => end.sub(start).length()

export const edgeCoordinateSystemMatrix = ({ coordinate_system: coordinate }: Edge): Matrix4 => {
  const xDirection = coordinate.x_direction
  const yDirection = coordinate.y_direction
  const zDirection = coordinate.z_direction

  return new Matrix4().makeBasis(xDirection.v, yDirection.v, zDirection.v)
}

export const globalToLocalForce = (force: Force, domain: Domain) => {
  const forceVector = toVector(force)
  const localForce = edgeCoordinateSystemMatrix(domain)
  const localForceVector = forceVector.applyMatrix4(localForce.transpose())
  return localForceVector
}

export const localToGlobalForce = (force: Force, domain: Domain) => {
  const forceVector = toVector(force)
  const localForce = edgeCoordinateSystemMatrix(domain)
  return forceVector.applyMatrix4(localForce)
}

export const loadPointOnDomain = (relativePosition: number, start: Force, end: Force) => {
  const domainStart = toVector(start)
  const domainEnd = toVector(end)
  const domainDirectionWithLength = domainEnd.clone().sub(domainStart)
  const domainOffSet = domainDirectionWithLength.clone().multiplyScalar(relativePosition)
  const loadPoint = domainStart.clone().add(domainOffSet)

  return loadPoint
}

export const magnitudeArrowDirection = (magnitude: Force, start: Force, end: Force) => ({
  from: magnitude.z <= 0 ? toVector(start) : toVector(end),
  to: magnitude.z <= 0 ? toVector(end) : toVector(start),
})

export const scaleVector = (vector: Vector3, scale: number) => {
  const { x, y, z } = vector
  const scaledX = x / scale
  const scaledY = y / scale
  const scaledZ = z / scale

  return new Vector3(scaledX, scaledY, scaledZ)
}

export const snapAbsoluteInputValue = (value: number, snapValues: SnapValues[]): number => {
  const snapValue = find(snapValues, ({ absolutePosition }) => {
    const lower = absolutePosition - absoluteTransmitterSnapTolerance
    const upper = absolutePosition + absoluteTransmitterSnapTolerance

    return lower <= value && value <= upper
  })

  if (isUndefined(snapValue)) return value

  return snapValue.absolutePosition || value
}

export const snapRelativeInputValue = (
  value: number,
  snapValues: SnapValues[],
  domainLength: number,
): number => {
  const relativeTolerance = absoluteTransmitterSnapTolerance / domainLength

  const snapValue = find(snapValues, ({ relativePosition }) => {
    const lower = relativePosition - relativeTolerance
    const upper = relativePosition + relativeTolerance

    return lower <= value && value <= upper
  })

  if (isUndefined(snapValue)) return value

  return snapValue.relativePosition
}

export const findRelativeSnapValue = (
  value: number,
  snapValues: SnapValues[],
  domainLength: number,
) => {
  const relativeTolerance = absoluteTransmitterSnapTolerance / domainLength

  const snapValue = find(snapValues, ({ relativePosition }) => {
    const lower = relativePosition - relativeTolerance
    const upper = relativePosition + relativeTolerance

    return lower <= value && value <= upper
  })
  return snapValue
}

export const getShapeMatrixTransform = (shape: ImmutableVector3[]): Matrix4 => {
  // calculate normal to extrude along
  const norm = new Vector3()
    .crossVectors(shape[1].sub(shape[0]).v, shape[2].sub(shape[0]).v)
    .normalize()

  const axisX = shape[1].sub(shape[0]).v
  const axisY = shape[2].sub(shape[0]).v
  const axisZ = norm

  const transform = new Matrix4().makeBasis(axisX, axisY, axisZ)
  transform.setPosition(shape[0].v)

  return transform
}

export const getShapeObject = (
  shape: ImmutableVector3[],
  openings: ImmutableVector3[][],
  matrixTransform: Matrix4,
): Shape => {
  const matrixRetransform = new Matrix4().copy(matrixTransform).invert()

  // create local 2D points because a extruded geometry is two dimensional by
  // default. later in the transformation the calculated above will be used to
  // move the created geometry to the correct place in scene
  const localPoints: Vector3[] = []
  const twoDPoints: Vector2[] = []

  shape.forEach(point => {
    localPoints.push(point.v.applyMatrix4(matrixRetransform))
  })

  localPoints.forEach(point => {
    twoDPoints.push(new Vector2(point.x, point.y))
  })

  let minPointY = Number.POSITIVE_INFINITY
  let maxPointY = Number.NEGATIVE_INFINITY
  let minPointX = Number.POSITIVE_INFINITY
  let maxPointX = Number.NEGATIVE_INFINITY

  twoDPoints.forEach(p => {
    if (p.y < minPointY) minPointY = p.y
    if (p.y > maxPointY) maxPointY = p.y
    if (p.x < minPointX) minPointX = p.x
    if (p.x > maxPointX) maxPointX = p.x
  })

  // create a shape as the basis for the extruded geometry
  const shapeObj = new Shape(twoDPoints)

  // add holes to the shape (if there are any)
  for (const holePoints of openings) {
    const localPoints = holePoints.map(holePoint => holePoint.v.applyMatrix4(matrixRetransform))

    const vec2Points = localPoints.map(holePoint => {
      let x = holePoint.x
      let y = holePoint.y

      if (holePoint.x < minPointX) x = minPointX
      if (holePoint.x > maxPointX) x = maxPointX
      if (holePoint.y < minPointY) y = minPointY
      if (holePoint.y > maxPointY) y = maxPointY

      return new Vector2(x, y)
    })

    const hole = new Shape(vec2Points)
    shapeObj.holes.push(hole)
  }

  return shapeObj
}

export const getShapeEdges = (shapeObject: Shape, thickness: number) => {
  // we set a really small offset here because the line segments generated by
  // EdgesGeometry for the openings will be buggy if they are on the exact
  // same x, y, or z axis. this case happens quite often for the sample models
  // and will result in a line between two openings, if their upper bound is
  // on the same height and axis. this is the only workaround we found without
  // re-implementing EdgesGeometry ourselves
  const clonedShape = shapeObject.clone()
  clonedShape.holes = clonedShape.holes.map((hole, index) => {
    const offset = index * 0.00001
    return new Shape(hole.getPoints().map(p => p.set(p.x + offset, p.y + offset)))
  })

  // it is not optimal that we basically create the extrude geometry twice,
  // but there seems to be no other way to declare line segments declarative
  const extrudeGeometry = new ExtrudeGeometry(clonedShape, {
    bevelEnabled: false,
    depth: thickness,
  })

  return new EdgesGeometry(extrudeGeometry, 15)
}

// eslint-disable-next-line space-before-function-paren
export function createMeshStandardMaterial(parameters: MeshStandardMaterialParameters) {
  return new MeshStandardMaterial(parameters)
}

export const createMeshStandardMaterialMemoized = memoize(
  createMeshStandardMaterial,
  (params: MeshStandardMaterialParameters) => JSON.stringify(params),
)

// eslint-disable-next-line space-before-function-paren
export function createLineBasicMaterial(parameters: LineBasicMaterialParameters) {
  return new LineBasicMaterial(parameters)
}

export const createLineBasicMaterialMemoized = memoize(
  createLineBasicMaterial,
  (params: LineBasicMaterialParameters) => JSON.stringify(params),
)
